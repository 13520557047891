import React from 'react';

import Social from './Social';
import EmailForm from './EmailForm';
import Button from 'react-bootstrap/Button'

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            showForm: false
        }
    }

    toggleForm = () => {
        var form = this.state.showForm;
        this.setState({
            showForm: !form
        });
    }

    render() { 
        return ( 
        <div className="App-body">
            <h1>Mark Clancy</h1>
            Full Stack Software Engineer and <a href='https://photos.markwclancy.com'>Photographer</a>
            <Social />
            <Button variant='outline-light' onClick={this.toggleForm.bind(this)}>Contact Me</Button>
            {(this.state.showForm) ? <EmailForm /> : ''}
            
        </div> );
    }
}
 
export default Home;