import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'


const Social = () => {
    var iconStyle = {
        padding: "20px"
    }
    var iconSize = "4x"
    
    return (
    <span>
        <FontAwesomeIcon className='clickable' icon={faLinkedin} size={iconSize} style={iconStyle} onClick={()=> window.open("https://www.linkedin.com/in/mark-clancy/", "_blank")}/>
        <FontAwesomeIcon className='clickable' icon={faInstagram} size={iconSize} style={iconStyle} onClick={()=> window.open("https://www.instagram.com/markymark_cl/", "_blank")}/>
    </span>);
}
 
export default Social;